// import { useState, Fragment } from "react";
import { useState, Fragment } from "react";
import { connect } from "react-redux";
import { Link, Navigate, useParams } from "react-router-dom";
import { resetPassword } from "../../actions/auth";
import PropTypes from "prop-types";
import { setAlert } from "../../actions/alert";
import Alert from "../shared/Alert";

const ResetPassword = ({ resetPassword, setAlert, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: "",
    newPassword: "",
    comfirmPassword: "",
  });

  const { token } = useParams();
  const { email, newPassword, comfirmPassword } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setAlert("Please type your email", "danger");
    } else if (!newPassword || !comfirmPassword) {
      setAlert("Please type your new password", "danger");
    } else if (newPassword !== comfirmPassword) {
      setAlert("Passwords do not match", "danger");
    } else {
      resetPassword(token, { email, newPassword, comfirmPassword });

      //clear the data
      setFormData({
        email: "",
        newPassword: "",
        comfirmPassword: "",
      });
    }
  };

  return (
    <Fragment>
      <section className="container">
        <h1 className="large text-primary">Reset Password</h1>
        <p className="lead">
          <i className="fas fa-user" /> Type your new password.
        </p>
        <form className="form" onSubmit={onSubmit}>
          <div className="form-group">
            <input
              type="email"
              placeholder="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={onChange}
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              placeholder="New Password"
              name="newPassword"
              value={newPassword}
              onChange={onChange}
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              placeholder="Comfirm Password"
              name="comfirmPassword"
              value={comfirmPassword}
              onChange={onChange}
            />
          </div>
          <input type="submit" className="btn btn-primary" value="Reset" />
        </form>
        <p className="my-1">
          Go back to login? <Link to="/login">Login</Link>
        </p>
        <span>
          <Alert />
        </span>
      </section>
    </Fragment>
  );
};

ResetPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { resetPassword, setAlert })(
  ResetPassword
);
