import { Fragment } from "react";
import "./pagination.css";
function Pagination({ totalPage, postPerPage, currentPage, setCurrentPage }) {
  let pages = [];
  for (let i = 1; i <= Math.ceil(totalPage / postPerPage); i++) {
    pages.push(i);
  }

  return (
    <Fragment>
      <div className="pagination">
        {pages.map((page, index) => {
          return (
            <button
              key={index}
              onClick={() => setCurrentPage(page)}
              className={page === currentPage ? "active" : ""}
            >
              {page}
            </button>
          );
        })}
      </div>
    </Fragment>
  );
}

export default Pagination;
