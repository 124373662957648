import { Link } from "react-router-dom";
import { useState, Fragment } from "react";
import Alert from "../shared/Alert";
import { forgetPassword, login } from "../../actions/auth";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const ForgetPassword = ({ forgetPassword }) => {
  const [formData, setFormData] = useState({
    email: "",
  });

  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...email, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    forgetPassword({ email });
  };

  return (
    <Fragment>
      <section className="container">
        <h1 className="large text-primary">Find your account</h1>
        <p className="lead">
          <i className="fas fa-user" /> Please enter your email to search for
          your account.
        </p>
        <form className="form" onSubmit={onSubmit}>
          <div className="form-group">
            <input
              type="email"
              placeholder="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={onChange}
            />
          </div>
          <input type="submit" className="btn btn-primary" value="Search" />
        </form>
        <p className="my-1">
          Go back to Login? <Link to="/login">Cancel</Link>
        </p>
        <span>
          <Alert />
        </span>
      </section>
    </Fragment>
  );
};

ForgetPassword.propTypes = {
  forgetPassword: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

export default connect(null, { forgetPassword })(ForgetPassword);
