import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getProfiles } from "../../actions/profile";
import propsTypes from "prop-types";
import UsersList from "./UsersList";
import Pagination from "../shared/pagination/Pagination";
import "./Admin.css";
const ManageUsers = ({ getProfiles, profile }) => {
  useEffect(() => {
    getProfiles();
  }, [getProfiles]);

  const [searchName, setSearchName] = useState("");

  const inputHandleChange = (e) => {
    setSearchName(e.target.value);
  };

  //Filter the search name
  const filterNames = profile.filter((profile) =>
    profile.user.name.toLowerCase().includes(searchName.toLocaleLowerCase())
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(3);

  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;
  const currentPost = filterNames.slice(firstPostIndex, lastPostIndex);

  console.log("List of search Names ", filterNames);
  return (
    <Fragment>
      <section className="container">
        <div className="search-container">
          <h2>Search By Name</h2>
          <input
            type="text"
            placeholder="Search .."
            name="search"
            value={searchName}
            onChange={inputHandleChange}
          />
          <i className="fa fa-search"></i>
        </div>
        <h1 className="large text-primary">Manage Users</h1>
        <table>
          <tr>
            <th>Name</th>
            <th>Status</th>
          </tr>
          {currentPost.map((p) => (
            <UsersList key={p._id} profile={p} />
          ))}
        </table>
      </section>
      <Pagination
        totalPage={profile.length}
        postPerPage={postPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </Fragment>
  );
};

ManageUsers.propsTypes = {
  getProfiles: propsTypes.func.isRequired,
};

//Map redux state to components props
const mapStateToProps = (state) => {
  const profile = state.profile.profiles;
  return {
    profile,
  };
};

export default connect(mapStateToProps, { getProfiles })(ManageUsers);
