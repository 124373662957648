import React from "react";
import { useEffect } from "react";
import "./Admin.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropsTypes from "prop-types";
import { deleteAccountById } from "../../actions/profile";
function UsersList({ deleteAccountById, profile }) {
  const { user, membership } = profile;
  const { _id, name } = user;
  const { active } = membership[0];

  return (
    <>
      <tr>
        <td>
          <i className="fas fa-user" /> {" " + name}
        </td>
        <td className={active ? "text-success" : "text-danger"}>
          {active ? "Active" : "Inactive"}
        </td>
      </tr>
      <Link to={`/edit-user/${_id}`} className="btn btn-primary">
        Edit
      </Link>
      <button
        type="button"
        className="btn btn-danger"
        onClick={() => deleteAccountById(_id)}
      >
        Delete
      </button>
    </>
  );
}

PropsTypes.UsersList = {
  deleteAccountById: PropsTypes.func.isRequired,
};

export default connect(null, { deleteAccountById })(UsersList);
